import React from 'react'
import styled from 'styled-components'
import { Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { useStores } from '../contexts'

const Container = styled.div`
  overflow: hidden;
  border: ${(props) => props.theme.border};
  height: 100%;
  min-width: 100px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ContentContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CopyPasteUpload = styled(Upload.Dragger)`
  height: 100%;
  padding: 0 1rem;
  min-width: 200px;
`

const StyledUpload = styled(Upload.Dragger)`
  height: 100%;
  padding: 0 1rem;
  min-width: 200px;
`

const StyledIcon = styled(InboxOutlined)`
  color: ${(props) => props.theme.primaryColor};
  font-size: 30px;
  margin-bottom: 1rem;
`

const SmallUpload = styled(Upload.Dragger)`
  height: 100%;
  width: 100%;
  padding: 0 1rem;
  .anticon {
    margin: 0;
  }
`

export const UploadCartCard = ({ bucketID, small }) => {
  const { pullerStore } = useStores()

  const handleUpload = async (e) => {
    let uploadedScreenshotId = await pullerStore.uploadScreenshot(e, bucketID)
    pullerStore.createCart(bucketID, uploadedScreenshotId)
  }

  const handlePaste = (e) => {
    let items = e.clipboardData.items
    for (let item of items) {
      if (item.kind === 'file') {
        let file = item.getAsFile()
        handleUpload({ file })
      }
    }
  }

  return !small ? (
    <Container onPaste={(e) => handlePaste(e)}>
      <ContentContainer>
        <StyledIcon />
        <p>Upload Screenshot to Create New Cart</p>
      </ContentContainer>
      <StyledUpload
        accept=".png,.jpg,.jpeg"
        showUploadList={false}
        customRequest={(e) => handleUpload(e)}
      >
        <p>Click/Drag Here</p>
      </StyledUpload>
      <CopyPasteUpload
        accept=".png,.jpg,.jpeg"
        showUploadList={false}
        onPaste={(e) => handlePaste(e)}
        openFileDialogOnClick={false}
      >
        <p>Paste Here</p>
      </CopyPasteUpload>
    </Container>
  ) : (
    <SmallUpload
      accept=".png,.jpg,.jpeg"
      showUploadList={false}
      customRequest={(e) => handleUpload(e)}
    >
      <StyledIcon />
    </SmallUpload>
  )
}
