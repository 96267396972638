import React from 'react'
import styled from 'styled-components'

const Container = styled.span`
  background-color: #a61d24;
  padding: 2px 5px;
  color: white;
  font-size: 0.65rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal !important;
`

export const Badge = ({ count }) => {
  let formatted = count
  if (formatted >= 100) {
    formatted = '99+'
  }
  return <Container>{formatted}</Container>
}
