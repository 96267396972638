import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useStores } from '../contexts'
import { message } from 'antd'
import { Layout, PullerEventsTab, PullerSideNav } from '../components'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { Tabs } from 'antd'

const { TabPane } = Tabs

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

const Title = styled.h2`
  font-size: 1.3rem;
  font-weight: 500;
`

export const PullerEvents = observer(() => {
  const { userStore, pullerStore } = useStores()
  const history = useHistory()

  useEffect(() => {
    if (userStore.groups.size == 0) {
      return
    }

    if (!userStore.groups.has('pullers') && userStore.groups.has('buyers')) {
      history.push('/buyer/events')
      message.info(
        'Redirected to the buyer page since you do not have puller access',
      )
    } else if (!userStore.groups.has('pullers')) {
      history.push('/login')
    }

    if (pullerStore.buckets.length === 0) {
      pullerStore.fetchData(userStore.token)
    }
  })

  useEffect(() => {
    if (pullerStore.socket) {
      return
    }
    pullerStore.connectSocket()
  }, [pullerStore.socket])

  return userStore.isLoading ? (
    <Layout>loading</Layout>
  ) : (
    <Layout sidebar={<PullerSideNav />}>
      <TitleContainer>
        <Title>Events</Title>
      </TitleContainer>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Active" key="1">
          <PullerEventsTab isActive={1} />
        </TabPane>
        <TabPane tab="Archived" key="2">
          <PullerEventsTab />
        </TabPane>
      </Tabs>
    </Layout>
  )
})
