import React from 'react'
import styled from 'styled-components'
import { media, sizes } from '../styles'
import { ReactComponent as Spaceship } from '../assets/rocket.svg'
import { ReactComponent as Moon } from '../assets/moon.svg'

const Container = styled.section`
  margin-top: ${(props) => props.theme.mobileHeaderHeight};
  width: 100%;
  display: flex;
  flex-direction: column;

  ${media.forMediumUp`
    margin-top: 0;
    max-width: ${sizes.forMediumUp.width + 'px'};
  `}

  ${media.forLargeUp`
    max-width: ${sizes.forLargeUp.width + 'px'};
  `}

  ${media.forXLargeUp`
    max-width: ${sizes.forXLargeUp.width + 'px'};
  `}

  ${media.forXXLargeUp`
    max-width: ${sizes.forXXLargeUp.width + 'px'};
  `}
`

const Headline = styled.h1`
  margin-bottom: 1.5rem /* 24px */;
  font-size: 2.5rem /* 40px */;
  letter-spacing: -0.025em;
  font-weight: 800;
  text-align: center;
  line-height: 1;

  ${media.forMediumUp`
    font-size: 2.75rem/* 44px */;
    text-align: left;
  `}

  ${media.forLargeUp`
    font-size: 4.5rem/* 72px */;
  `}

  span {
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.forMediumUp`
      flex-direction: row;
    `}
  }

  span.color {
    color: ${(props) => props.theme.primaryColor};
  }
`

const EmojiContainer = styled.span`
  display: flex;
  svg {
    height: 3.5rem;

    ${media.forMediumUp`
      height: 5rem;
      margin-left: 0.5rem;
    `}
  }
`

const StyledInput = styled.input`
  background-color: ${(props) => props.theme.black};
  width: 100%;
  padding: 0.75rem;
  color: #808080;
  transition: 'all .15s ease';
  font-size: 1rem;
  line-height: 1.5rem;
  border-width: 1px;
  border-radius: 0.25rem;
  border-color: rgba(55, 48, 163, 1);
  outline: 2px solid transparent;
  outline-offset: 2px;
  margin-bottom: 0.5rem;

  &:focus {
    border-color: rgba(79, 70, 229, 1);
    outline: none;
    outline-offset: 2px;
  }

  ${media.forMediumUp`
    width: 33.33%;
  `}
`

const StyledButton = styled.button`
  transition: 'all .15s ease';
  background-color: rgba(79, 70, 229, 1);
  width: 100%;
  padding: 0.75rem 1.5rem;
  color: ${(props) => props.theme.white};
  font-size: 1rem;
  font-weight: 700;
  border-radius: 0.25rem;
  white-space: nowrap;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  outline: 2px solid transparent;
  outline-offset: 2px;

  ${media.forMediumUp`
    margin-left: 0.5rem;
    width: min-content;
  `}

  &:hover {
    background-color: rgb(99, 102, 241);
  }

  &:active {
    background-color: rgb(67, 56, 202);
  }

  &:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
`

export const Hero = () => {
  return (
    <>
      <Container>
        <Headline>
          <span>Automate your reselling</span>
          <span className="color">
            to the moon.
            <EmojiContainer>
              <Spaceship />
              <Moon />
            </EmojiContainer>
          </span>
        </Headline>
        <div>
          <StyledInput type="text" placeholder="Email" />
          <StyledButton>Request Early Access</StyledButton>
        </div>
      </Container>
    </>
  )
}
