import React from 'react'
import styled from 'styled-components'
import { Image, Button } from 'antd'
import { observer } from 'mobx-react'
import { useStores } from '../contexts'

const Container = styled.div`
  overflow: hidden;
  border: ${(props) => props.theme.border};
  height: 100%;
`

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  transition: all 0.1s ease;
  display: flex;
  justify-content: center;
  padding: 1rem;
  img,
  picture {
    height: 500px;
    /* width: 100%; */
    object-fit: contain;
    transition: all 0.3s ease;
  }
  .ant-image {
    width: 100%;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;

  & > p {
    margin-bottom: 0.3rem;
  }

  span {
    color: ${({ state }) => {
      if (state === 'Approved') {
        return '#1cad25'
      } else if (state === 'Declined') {
        return '#a61d24'
      }
    }};
  }
`

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  button:last-child {
    margin-left: 10px;
  }
`

export const CartCard = observer(({ data }) => {
  const { pullerStore } = useStores()

  const handleUpdateCartCheckoutState = (checkoutState) => {
    pullerStore.updateCartCheckoutState(data.id, checkoutState)
  }

  return (
    <Container>
      <ImageContainer>
        {pullerStore.screenshots[data.cart_screenshot] && (
          <Image src={pullerStore.screenshots[data.cart_screenshot].url} />
        )}
      </ImageContainer>
      <ContentContainer state={data.approval_state}>
        <p>
          Approval State: <span>{data.approval_state}</span>
        </p>
        <p>Checkout State: {data.checkout_state}</p>
      </ContentContainer>
      <ActionContainer>
        {data.checkout_state !== 'Incomplete' && (
          <Button onClick={() => handleUpdateCartCheckoutState('incomplete')}>
            Incomplete
          </Button>
        )}
        {data.checkout_state !== 'Completed' && (
          <Button onClick={() => handleUpdateCartCheckoutState('completed')}>
            Completed
          </Button>
        )}
        {data.checkout_state !== 'Expired' && (
          <Button onClick={() => handleUpdateCartCheckoutState('expired')}>
            Expired
          </Button>
        )}
      </ActionContainer>
    </Container>
  )
})
