import React from 'react'
import './styles/globals.css'
// import 'antd/dist/antd.css'
import 'antd/dist/antd.dark.css'
import { PATH_PREFIX, GTM_ID } from './config'
import { Helmet } from 'react-helmet'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle, theme } from './styles'
import { StoresProvider, useStores } from './contexts'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import {
  Home,
  About,
  Login,
  NotFound,
  BuyerEvents,
  BuyerEventDetails,
  PullerEvents,
  PullerEventDetails,
} from './pages'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: GTM_ID,
}
TagManager.initialize(tagManagerArgs)

const ProtectedRoute = ({ children, path, exact }) => {
  const { userStore } = useStores()

  return !userStore.token ? (
    <Redirect to="/login" />
  ) : (
    <Route path={path} exact={exact}>
      {children}
    </Route>
  )
}

function App() {
  return (
    <>
      <Helmet>
        <title>Rennet Tickets</title>
      </Helmet>
      <ThemeProvider theme={theme}>
        <StoresProvider>
          <GlobalStyle />
          <Router basename={PATH_PREFIX}>
            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <ProtectedRoute exact path="/puller/events/:id">
                <PullerEventDetails />
              </ProtectedRoute>
              <ProtectedRoute exact path="/puller/events">
                <PullerEvents />
              </ProtectedRoute>
              <ProtectedRoute exact path="/puller">
                <Redirect to="/puller/events" />
              </ProtectedRoute>
              <ProtectedRoute exact path="/buyer/events/:id">
                <BuyerEventDetails />
              </ProtectedRoute>
              <ProtectedRoute exact path="/buyer/events">
                <BuyerEvents />
              </ProtectedRoute>
              <ProtectedRoute exact path="/buyer">
                <Redirect to="/buyer/events" />
              </ProtectedRoute>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </Router>
        </StoresProvider>
      </ThemeProvider>
    </>
  )
}

export default App
