import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStores } from '../contexts'
import { List, Button } from 'antd'
import _ from 'lodash'

export const BuyerEventsTab = observer((props) => {
  const { buyerStore } = useStores()
  let sortedBuckets = []
  if (props.isActive) {
    sortedBuckets =
      buyerStore.buckets && _.sortBy(buyerStore.activeBuckets, 'description')
  } else {
    sortedBuckets =
      buyerStore.buckets && _.sortBy(buyerStore.archivedBuckets, 'description')
  }

  const handleClick = (bucket) => {
    let newState = bucket.state === 'Active' ? 'archived' : 'active'
    let newType = bucket.type.toLowerCase()

    buyerStore.updateBucket(bucket.id, {
      description: bucket.description,
      type: newType,
      state: newState,
    })
  }

  const StateButton = (props) => (
    <Button onClick={() => handleClick(props.bucket)}>
      {props.bucket.state == 'Active' ? 'Archive' : 'Activate'}
    </Button>
  )

  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={sortedBuckets}
        renderItem={(b) => (
          <List.Item actions={[<StateButton key={b.id} bucket={b} />]}>
            <List.Item.Meta
              title={<Link to={`/buyer/events/${b.id}`}>{b.description}</Link>}
              description={`Type: ${b.type}`}
            />
          </List.Item>
        )}
      />
    </div>
  )
})
