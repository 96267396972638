import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStores } from '../contexts'
import { List } from 'antd'
import _ from 'lodash'

export const PullerEventsTab = observer((props) => {
  const { pullerStore } = useStores()
  let sortedBuckets = []
  if (props.isActive) {
    sortedBuckets =
      pullerStore.buckets && _.sortBy(pullerStore.activeBuckets, 'description')
  } else {
    sortedBuckets =
      pullerStore.buckets &&
      _.sortBy(pullerStore.archivedBuckets, 'description')
  }

  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={sortedBuckets}
        renderItem={(b) => (
          <List.Item>
            <List.Item.Meta
              title={<Link to={`/puller/events/${b.id}`}>{b.description}</Link>}
              description={`Type: ${b.type}`}
            />
            <div>{b.buyer_username}</div>
          </List.Item>
        )}
      />
    </div>
  )
})
