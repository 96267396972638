import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStores } from '../contexts'
import { Badge } from '../components'
import styled from 'styled-components'
import _ from 'lodash'
import { Menu } from 'antd'
const MenuItem = Menu.Item

const MenuTitle = styled.p`
  padding-left: 24px;
  margin-top: 1.2rem;
  color: hsla(0, 0%, 100%, 0.65);
  font-size: 1.2rem;
`

const StyledMenuItem = styled(MenuItem)`
  white-space: normal;
  line-height: normal !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${(props) => (props.isbuyer ? '1rem' : '0.8rem')};
  font-weight: ${(props) => (props.isbuyer ? '500' : '300')};
`

export const PullerSideNav = observer(() => {
  const { pullerStore } = useStores()

  const renderBadge = (bucketId) => {
    let unreadCount = _.get(
      pullerStore.bucketNotifications,
      `${bucketId}.unreadCount`,
      0,
    )
    if (unreadCount > 0) {
      return <Badge count={unreadCount} />
    }
  }

  return (
    <>
      <MenuTitle>
        <Link to="/puller/events">My Events</Link>
      </MenuTitle>
      <Menu mode="inline">
        {pullerStore.buckets &&
          _.sortBy(pullerStore.activeBuckets, 'created_time').map((bucket) => (
            <StyledMenuItem key={`bucket-${bucket.id}`}>
              <Link to={`/puller/events/${bucket.id}`}>
                {bucket.description}
              </Link>
              {renderBadge(bucket.id)}
            </StyledMenuItem>
          ))}
      </Menu>
    </>
  )
})
