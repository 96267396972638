import React from 'react'
import { Select } from 'antd'
const { Option } = Select

export const renderBucketTypeOptions = () => (
  <>
    <Option value="ticketmaster.com">ticketmaster.com</Option>
    <Option value="axs.com">axs.com</Option>
    <Option value="none">Other</Option>
  </>
)

export const renderBucketStateOptions = () => (
  <>
    <Option value="active">Active</Option>
    <Option value="archived">Archived</Option>
  </>
)
