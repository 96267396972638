import React from 'react'
import styled from 'styled-components'
import { Table, Image } from 'antd'
import { observer } from 'mobx-react'
import { useStores } from '../contexts'
import { PullerActionCell } from '../components'

const Container = styled.div`
  padding-bottom: 3rem;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: fit-content;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  & > p {
    margin-bottom: 0;
  }
`

const StyledTable = styled(Table)`
  table tr:nth-child(2n) {
    background-color: rgba(50, 50, 50);
  }
  overflow-x: scroll;

  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) =>
    `rgba(70, 70, 70, 1) ${props.theme.backgroundColor}`};

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.backgroundColor};
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(70, 70, 70, 1);
    border-radius: 20px;
    border: 3px solid rgba(70, 70, 70, 1);
  }
`

const ImageCell = observer(({ screenshotId }) => {
  const { pullerStore } = useStores()
  return (
    <ImageContainer>
      {pullerStore.screenshots[screenshotId] && (
        <Image src={pullerStore.screenshots[screenshotId].url} width={60} />
      )}
    </ImageContainer>
  )
})

const formatCarts = (data) => {
  return data.carts.map((c) => {
    let out = {
      key: c.id,
      ...c,
      ...c.detail,
    }
    delete out.detail
    return out
  })
}

export const PullerCartsTable = observer(({ data }) => {
  const { pullerStore } = useStores()
  let formattedCarts = data && formatCarts(data)

  const columns = [
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => <PullerActionCell data={record} />,
    },
    {
      title: 'Created At',
      dataIndex: 'created_time',
      key: 'created',
      sorter: (a, b) => a.created_time < b.created_time,
      sortDirections: ['descend', 'ascend'],
      render: (created_time) => {
        let parsed = new Date(created_time)
        return (
          <ContentContainer>
            <p>{parsed.toUTCString()}</p>
          </ContentContainer>
        )
      },
    },
    {
      title: 'Approval State',
      dataIndex: 'approval_state',
      key: 'approvalState',
      filters: [
        {
          text: 'Pending',
          value: 'Pending',
        },
        {
          text: 'Approved',
          value: 'Approved',
        },
        {
          text: 'Declined',
          value: 'Declined',
        },
      ],
      onFilter: (value, record) => record.approval_state.indexOf(value) === 0,
      sorter: (a, b) => a.approval_state > b.approval_state,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Checkout State',
      dataIndex: 'checkout_state',
      key: 'checkoutState',
      filters: [
        {
          text: 'Incomplete',
          value: 'Incomplete',
        },
        {
          text: 'Completed',
          value: 'Completed',
        },
        {
          text: 'Expired',
          value: 'Expired',
        },
      ],
      onFilter: (value, record) => record.checkout_state.indexOf(value) === 0,
      sorter: (a, b) => a.checkout_state > b.checkout_state,
      sortDirections: ['descend', 'ascend'],
      // render: (checkout_state, record) => (
      //   <CheckoutStateCell cartId={record.id} checkoutState={checkout_state} />
      // ),
    },
    {
      title: 'OCR State',
      dataIndex: 'ocr_state',
      key: 'ocrState',
      filters: [
        {
          text: 'Pending',
          value: 'Pending',
        },
        {
          text: 'Success',
          value: 'Success',
        },
        {
          text: 'Cannot OCR',
          value: 'Cannot OCR',
        },
        {
          text: 'Parse Failed',
          value: 'Parse Failed',
        },
        {
          text: 'OCR Failed',
          value: 'OCR Failed',
        },
      ],
      onFilter: (value, record) => record.ocr_state.indexOf(value) === 0,
      sorter: (a, b) => a.ocr_state > b.ocr_state,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Delivery Method',
      dataIndex: 'delivery_method',
      key: 'delivery',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Total Price',
      dataIndex: 'total_cents',
      key: 'total',
      sorter: (a, b) => a.total_cents > b.total_cents,
      sortDirections: ['descend', 'ascend'],
      render: (total_cents) => {
        if (!total_cents) {
          return
        }
        let dollars = total_cents / 100
        return dollars.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      },
    },
    {
      title: 'Price Per Ticket',
      dataIndex: 'price_per_ticket',
      key: 'price_per_ticket',
      render: (_, record) => {
        if (!record.total_cents || !record.quantity) {
          return
        }
        let cents_per_ticket = record.total_cents / record.quantity
        let dollars = cents_per_ticket / 100
        return dollars.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
      },
    },
    {
      title: 'Description',
      dataIndex: 'items',
      key: 'description',
      render: (items) => {
        if (!items || items.length === 0) {
          return
        }
        let descriptions = items.map((obj) => obj.description)
        return descriptions.join(' ')
      },
    },
    {
      title: 'Screenshot',
      dataIndex: 'cart_screenshot',
      key: 'screenshot',
      render: (cart_screenshot) => <ImageCell screenshotId={cart_screenshot} />,
    },
  ]

  return (
    <Container>
      <StyledTable
        columns={columns}
        dataSource={formattedCarts}
        pagination={false}
        rowKey="id"
        rowSelection={{
          selectedRowKeys: [pullerStore.selectedCartId],
          type: 'radio',
          columnWidth: 0,
          renderCell: () => <></>,
        }}
      />
    </Container>
  )
})
