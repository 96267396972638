import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useStores } from '../contexts'
import { message } from 'antd'
import {
  Layout,
  PullerCartsTable,
  PullerSideNav,
  UploadCartCard,
  NotificationBadgeButton,
} from '../components'
import { observer } from 'mobx-react'
import styled from 'styled-components'

const NotificationsContainer = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`

const SummaryContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;

  & > h2 {
    font-size: 1.3rem;
    font-weight: 500;
  }

  & > h3 {
    font-size: 1rem;
    font-weight: 300;
  }
`

const UploadContainer = styled.div`
  /* padding: 0 2rem; */
  width: 40%;
`

const TitleContainer = styled.span`
  display: flex;
  align-items: center;
`

export const PullerEventDetails = observer(() => {
  const { userStore, pullerStore } = useStores()
  const history = useHistory()
  const { id } = useParams()
  const data = pullerStore.getBucket(id)

  useEffect(() => {
    pullerStore.setSelectedBucket(id)
  })

  useEffect(() => {
    if (userStore.groups.size == 0) {
      return
    }
    if (!userStore.groups.has('pullers') && userStore.groups.has('buyers')) {
      history.push('/buyer/events')
      message.info(
        'Redirected to the puller page since you do not have puller access',
      )
    } else if (!userStore.groups.has('pullers')) {
      history.push('/login')
    }
  })

  useEffect(() => {
    if (pullerStore.socket) {
      return
    }
    pullerStore.connectSocket()
  }, [pullerStore.socket])

  useEffect(() => {
    if (pullerStore.buckets.length === 0) {
      pullerStore.fetchData()
    }
  })

  const infoElements = (
    <InfoContainer>
      <TitleContainer>
        <h2>{data && data.description}</h2>
      </TitleContainer>
      {data && (
        <h3>
          Link:{' '}
          <a href={data.link} target="_blank" rel="noreferrer">
            {data.link}
          </a>
        </h3>
      )}
      <h3>{data && `Type: ${data.type}`}</h3>
      <h3>{data && `State: ${data.state}`}</h3>
      <h3>{data && `Buyer: ${data.buyer_username}`}</h3>
    </InfoContainer>
  )

  return pullerStore.isLoading || userStore.isLoading ? (
    <Layout>loading</Layout>
  ) : (
    <Layout sidebar={<PullerSideNav />}>
      <NotificationsContainer>
        <NotificationBadgeButton bucketId={id} />
      </NotificationsContainer>
      <SummaryContainer>
        {infoElements}
        <UploadContainer>
          <UploadCartCard bucketID={id} />
        </UploadContainer>
      </SummaryContainer>
      {data && <PullerCartsTable data={data} />}
    </Layout>
  )
})
