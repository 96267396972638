import { WS_API_URL } from '../config'
import { makeAutoObservable } from 'mobx'
import _ from 'lodash'

export class BuyerStore {
  buyerApi
  userStore
  socket
  isLoading = false
  buckets = []
  bucketNotifications = {}
  screenshots = {}
  screenshotIds = []
  selectedBucketId = -1
  selectedCartId = -1

  constructor(buyerApi, userStore) {
    makeAutoObservable(this, {
      buyerApi: false,
      pullerApi: false,
      userStore: false,
    })

    this.buyerApi = buyerApi
    this.userStore = userStore
  }

  get activeBuckets() {
    return this.buckets.filter((b) => b.state === 'Active')
  }

  get archivedBuckets() {
    return this.buckets.filter((b) => b.state === 'Archived')
  }

  getCompletedDollars(bucketId) {
    let bucket = this.getBucket(bucketId)
    if (!bucket || _.get(bucket, 'carts').length === 0) {
      return
    }

    let sum = 0
    bucket.carts.forEach((c) => {
      let cents = _.get(c, 'detail.total_cents')
      if (
        typeof cents == 'number' &&
        _.get(c, 'checkout_state') === 'Completed'
      ) {
        sum += cents
      }
    })

    if (sum === 0) {
      return 0
    }
    return sum / 100
  }

  getCompletedTicketsCount(bucketId) {
    let bucket = this.getBucket(bucketId)
    if (!bucket || _.get(bucket, 'carts').length === 0) {
      return 0
    }
    let count = 0
    let na = false
    bucket.carts.forEach((c) => {
      let quantity = _.get(c, 'detail.quantity')
      if (!quantity) {
        na = true
      }

      if (
        typeof quantity == 'number' &&
        _.get(c, 'checkout_state') === 'Completed'
      ) {
        count += quantity
      }
    })

    if (na) return 'N/A'
    return count
  }

  getCompletedPricePer(bucketId) {
    let dollars = this.getCompletedDollars(bucketId)
    let count = this.getCompletedTicketsCount(bucketId)
    if (!dollars || count === 'N/A') {
      return 'N/A'
    }
    return dollars / count
  }

  getBucket(id) {
    return _.find(this.buckets, (b) => b.id === parseInt(id))
  }

  setSelectedBucket(id) {
    if (typeof id === 'string') {
      id = parseInt(id)
    }

    this.selectedBucketId = id
  }

  setBuckets(buckets) {
    this.buckets = buckets
    buckets.forEach((b) =>
      this.setBucketNotificationObjs(b.id, {
        unreadCount: 0,
        messages: [],
      }),
    )
  }

  setBucketNotificationObjs(bucketId, obj) {
    this.bucketNotifications[bucketId] = obj
  }

  setBucketNotificationMessages(bucketId, messages) {
    this.bucketNotifications[bucketId].messages = messages
  }

  setBucketNotificationCount(bucketId, count) {
    this.bucketNotifications[bucketId].unreadCount = count
  }

  setSelectedCart(id) {
    if (typeof id === 'string') {
      id = parseInt(id)
    }

    this.selectedCartId = id
  }

  setScreenshotIds(ids) {
    this.screenshotIds = ids
  }

  setIsLoading(bool) {
    this.isLoading = bool
  }

  async fetchData() {
    if (!this.userStore.groups.has('buyers') || !this.userStore.token) {
      return
    }

    try {
      this.setIsLoading(true)
      const res = await this.buyerApi.fetchBuckets(this.userStore.token)
      let data = await res.json()
      this.setBuckets(data)

      let carts = data.map((b) => b.carts).flat()
      this.setScreenshotIds(carts.map((c) => c.cart_screenshot))

      this.fetchScreenshotsFromIds(this.screenshotIds)
    } catch (error) {
      console.log(error)
    } finally {
      this.setIsLoading(false)
    }
  }

  async fetchScreenshotsFromIds(ids) {
    let res = await this.buyerApi.generateScreenshotUrls(this.userStore.token, {
      ids: ids,
    })
    let screenshotsData = await res.json()
    screenshotsData.forEach((d) => {
      this.updateScreenshotData(d)
    })
  }

  updateScreenshotData(data) {
    this.screenshots[data.id] = data
  }

  async createBucket(description, type, link = false) {
    let data = {
      description: description,
      type: type,
    }
    if (link) {
      data.link = link
    }

    await this.buyerApi.createBucket(this.userStore.token, data)
  }

  async updateBucket(id, data) {
    await this.buyerApi.updateBucket(this.userStore.token, id, {
      description: data.description,
      type: data.type,
      state: data.state,
      link: data.link,
    })
  }

  async deleteBucket(id) {
    await this.buyerApi.deleteBucket(this.userStore.token, id)
  }

  async approveCart(id) {
    let res = await this.buyerApi.updateCart(this.userStore.token, id, {
      approval_state: 'approved',
    })

    if (!res.ok) {
      let errData = await res.json()
      errData.timestamp = Date.now()
      console.log(errData)
      return
    }

    return res.json()
  }

  async declineCart(id) {
    let res = await this.buyerApi.updateCart(this.userStore.token, id, {
      approval_state: 'declined',
    })

    if (!res.ok) {
      let errData = await res.json()
      errData.timestamp = Date.now()
      console.log(errData)
      return
    }

    return res.json()
  }

  async resetCart(id) {
    let res = await this.buyerApi.updateCart(this.userStore.token, id, {
      approval_state: 'pending',
    })

    if (!res.ok) {
      let errData = await res.json()
      errData.timestamp = Date.now()
      console.log(errData)
      return
    }

    return res.json()
  }

  async updateCartQuantity(id, quantity) {
    let res = await this.buyerApi.updateCart(this.userStore.token, id, {
      quantity: quantity,
    })

    if (!res.ok) {
      let errData = await res.json()
      errData.timestamp = Date.now()
      console.log(errData)
      return
    }

    return res.json()
  }

  async updateCartPrice(id, total_cents) {
    let res = await this.buyerApi.updateCart(this.userStore.token, id, {
      total_cents: total_cents,
    })

    if (!res.ok) {
      let errData = await res.json()
      errData.timestamp = Date.now()
      console.log(errData)
      return
    }

    return res.json()
  }

  wsUpdateBucket(bucketData) {
    let i = _.findIndex(this.buckets, { id: bucketData.id })
    if (i === -1) {
      this.buckets.push(bucketData)
    } else {
      this.buckets[i] = bucketData
    }

    // add notifications obj
    if (!this.bucketNotifications[bucketData.id]) {
      this.setBucketNotificationObjs(bucketData.id, {
        unreadCount: 0,
        messages: [],
      })
    }
  }

  wsDeleteBucket(bucketData) {
    _.remove(this.buckets, { id: bucketData.id })
  }

  wsUpdateCart(cartData, adj) {
    let bucketI = _.findIndex(this.buckets, { id: cartData.bucket })
    if (bucketI === -1) return
    // update bucket notifications
    let notificationObj = this.bucketNotifications[cartData.bucket]
    let messages = notificationObj?.messages
    let message = {
      cartId: cartData.id,
      text: `Cart ${adj}`,
    }
    notificationObj.messages.push(message)
    this.setBucketNotificationMessages(cartData.bucket, messages)
    this.setBucketNotificationCount(
      cartData.bucket,
      notificationObj.unreadCount + 1,
    )

    let curCarts = this.buckets[bucketI].carts
    let cartI = _.findIndex(curCarts, { id: cartData.id })
    if (cartI !== -1) {
      // check version number to see if you still want to update.
      if (curCarts[cartI].version < cartData.version) {
        curCarts[cartI] = cartData
      }
      return
    }

    curCarts.push(cartData)
    // update screenshots for new cart
    this.screenshotIds.push(cartData.cart_screenshot)
    this.fetchScreenshotsFromIds([cartData.cart_screenshot])
  }

  connectSocket() {
    if (typeof window === 'undefined' || !this.userStore.token) return

    this.socket = new WebSocket(
      `${WS_API_URL}/ws?api_token=${this.userStore.token}`,
    )

    this.socket.onopen = () => {
      console.log('connected to websocket')
    }

    // add onmessage here
    this.socket.onmessage = (e) => {
      let m = JSON.parse(e.data)

      switch (m.type) {
        case 'bucket_created':
          this.wsUpdateBucket(m.data)
          break
        case 'bucket_updated':
          this.wsUpdateBucket(m.data)
          break
        case 'bucket_deleted':
          this.wsDeleteBucket(m.data)
          break
        case 'cart_created':
          this.wsUpdateCart(m.data, 'created')
          break
        case 'cart_updated':
          this.wsUpdateCart(m.data, 'updated')
          break
      }
    }
  }
}
