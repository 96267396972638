import React from 'react'
import styled from 'styled-components'
import { media, sizes } from '../styles'
import { NavBar } from '.'
import { Hero } from './Hero'

const Main = styled.div`
  min-height: calc(100vh - ${(props) => props.theme.mobileHeaderHeight});
  display: flex;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  margin-top: ${(props) =>
    props.addMobileMargin ? props.theme.mobileHeaderHeight : 0};

  ${media.forMediumUp`
    margin-top: 0;
    padding: 0 60px;
    padding-top: 2rem;
    max-width: ${(props) =>
      sizes.forMediumUp.width - props.theme.sidebarWidth + 'px'};
    margin-left: ${(props) => props.addSideMargin && '200px'};
    border-left: ${(props) => props.theme.border};
  `}

  ${media.forLargeUp`
      max-width: ${(props) =>
        props.addSideMargin
          ? `calc(100vw - ${props.theme.sidebarWidth}px)`
          : '100vw'};
  `}
`

const SideBarContainer = styled.div`
  display: none;

  ${media.forMediumUp`
    position: fixed;
    top:0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 200px;
    height: 100%;
  `}
`

const SideBarSubContainer = styled.div`
  margin-top: ${(props) => props.theme.desktopHeaderHeight};
  padding: 0.5rem 0;
  width: 100%;
`

export const Layout = ({ showHero, children, sidebar, showSideBar }) => {
  return (
    <>
      <NavBar />
      {showHero && <Hero />}
      {sidebar && (
        <SideBarContainer>
          <SideBarSubContainer>{sidebar}</SideBarSubContainer>
        </SideBarContainer>
      )}
      <Main>
        <ContentContainer
          addMobileMargin={!showHero}
          addSideMargin={!!sidebar || showSideBar}
        >
          {children}
        </ContentContainer>
      </Main>
    </>
  )
}
