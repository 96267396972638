import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useStores } from '../contexts'
import { message, Card, Statistic, Button, Input, Select } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import {
  Layout,
  BuyerCartsTable,
  BuyerSideNav,
  NotificationBadgeButton,
  renderBucketStateOptions,
  renderBucketTypeOptions,
} from '../components'
import { observer } from 'mobx-react'
import styled from 'styled-components'

const NotificationsContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`

const SummaryContainer = styled.div`
  display: flex;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;

  & > h2 {
    font-size: 1.3rem;
    font-weight: 500;
  }

  & > h3 {
    font-size: 1rem;
    font-weight: 300;
  }
`

const TitleContainer = styled.span`
  display: flex;
  align-items: center;
`

const StatsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 1rem;
`

const StyledCard = styled(Card)`
  min-width: 200px;
  margin-left: 1rem;
`

const IconContainer = styled.span`
  height: 100%;
  margin: 0 0 0.5rem 0.5rem;
  display: flex;
  align-items: center;
`

const StyledEditIcon = styled(EditOutlined)`
  color: ${(props) => props.theme.primaryColor};
`

const FieldContainer = styled.div`
  margin-bottom: 10px;
`

const StyledSelect = styled(Select)`
  min-width: 200px;
`

const ButtonContainer = styled.span`
  display: flex;
  align-items: baseline;

  button:last-child {
    margin-left: 10px;
  }
`

const StyledButton = styled(Button)`
  width: 150px;
`

export const BuyerEventDetails = observer(() => {
  const { userStore, buyerStore } = useStores()
  const history = useHistory()
  const { id } = useParams()
  const data = buyerStore.getBucket(id)
  const [isModifying, setIsModifying] = useState(false)
  const [bucketDescription, setBucketDescription] = useState(
    data && data.description,
  )
  const [bucketLink, setBucketLink] = useState(data && data.link)
  const [bucketType, setBucketType] = useState(data && data.type)
  const [bucketState, setBucketState] = useState(data && data.state)

  useEffect(() => {
    buyerStore.setSelectedBucket(id)
  })

  useEffect(() => {
    if (userStore.groups.size == 0) {
      return
    }
    if (!userStore.groups.has('buyers') && userStore.groups.has('pullers')) {
      history.push('/puller/events')
      message.info(
        'Redirected to the puller page since you do not have buyer access',
      )
    } else if (!userStore.groups.has('buyers')) {
      history.push('/login')
    }
  })

  useEffect(() => {
    if (buyerStore.socket) {
      return
    }
    buyerStore.connectSocket()
  }, [buyerStore.socket])

  useEffect(() => {
    if (buyerStore.buckets.length === 0) {
      buyerStore.fetchData()
    }
  })

  const handleArchive = () => {
    buyerStore.updateBucket(data.id, {
      description: data.description,
      type: data.type.toLowerCase(),
      state: 'archived',
      link: data.link,
    })
  }

  const handleSave = () => {
    buyerStore.updateBucket(data.id, {
      description: bucketDescription,
      type: bucketType,
      state: bucketState,
      link: bucketLink,
    })
    setIsModifying(false)
  }

  const infoElements = (
    <InfoContainer>
      <TitleContainer>
        <h2>{data && data.description}</h2>
        <IconContainer>
          <StyledEditIcon onClick={() => setIsModifying(true)} />
        </IconContainer>
      </TitleContainer>
      {data && (
        <h3>
          Link:{' '}
          <a href={data.link} target="_blank" rel="noreferrer">
            {data.link}
          </a>
        </h3>
      )}
      <h3>{data && `Type: ${data.type}`}</h3>
      <ButtonContainer>
        <h3>{data && `State: ${data.state}`}</h3>
        {data && data.state == 'Active' && (
          <Button type="ghost" onClick={() => handleArchive()}>
            Archive
          </Button>
        )}
      </ButtonContainer>
    </InfoContainer>
  )

  const modifyInfoElements = (
    <InfoContainer>
      <FieldContainer>
        <Input
          size="large"
          value={bucketDescription}
          defaultValue={data && data.description}
          onChange={(e) => setBucketDescription(e.target.value)}
        />
      </FieldContainer>
      <FieldContainer>
        <Input
          size="large"
          value={bucketLink}
          defaultValue={data && data.link}
          onChange={(e) => setBucketLink(e.target.value)}
        />
      </FieldContainer>
      <FieldContainer>
        <StyledSelect
          defaultValue={data && data.type}
          onChange={(val) => setBucketType(val)}
        >
          {renderBucketTypeOptions()}
        </StyledSelect>
      </FieldContainer>
      <FieldContainer>
        <StyledSelect
          defaultValue={data && data.state}
          onChange={(val) => setBucketState(val)}
        >
          {renderBucketStateOptions()}
        </StyledSelect>
      </FieldContainer>
      <ButtonContainer>
        <StyledButton type="primary" onClick={() => handleSave()}>
          Save
        </StyledButton>
        <StyledButton type="ghost" onClick={() => setIsModifying(false)}>
          Cancel
        </StyledButton>
      </ButtonContainer>
    </InfoContainer>
  )

  return buyerStore.isLoading || userStore.isLoading ? (
    <Layout>loading</Layout>
  ) : (
    <Layout sidebar={<BuyerSideNav />}>
      <NotificationsContainer>
        <NotificationBadgeButton isBuyer bucketId={id} />
      </NotificationsContainer>
      <SummaryContainer>
        {isModifying ? modifyInfoElements : infoElements}
        <StatsContainer>
          <StyledCard>
            <Statistic
              title="Total Completed"
              value={buyerStore.getCompletedDollars(id)}
              prefix="$"
              precision={2}
            />
          </StyledCard>
          <StyledCard>
            <Statistic
              title="Per Ticket"
              value={buyerStore.getCompletedPricePer(id)}
              prefix="$"
              precision={2}
            />
          </StyledCard>
          <StyledCard>
            <Statistic
              title="# Of Tickets"
              value={buyerStore.getCompletedTicketsCount(id)}
            />
          </StyledCard>
        </StatsContainer>
      </SummaryContainer>
      {data && <BuyerCartsTable data={data} />}
    </Layout>
  )
})
