import React, { useState } from 'react'
import styled from 'styled-components'
import { media } from '../styles'
import { Hamburger } from '../components'
import { useHistory, Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStores } from '../contexts'

const ROUTES = [
  // {
  //   route: '/',
  //   text: 'Home',
  // },
]

const NavContainer = styled.div`
  height: ${(props) => props.theme.desktopHeaderHeight};
  background-color: ${(props) => props.theme.backgroundColor};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  z-index: 10;

  /* box-shadow: 0 2px 8px #f0f1f2; */
  border-bottom: 1px solid #303030;
  ${media.forSmallOnly`
    height: ${(props) => props.theme.mobileHeaderHeight};
    padding: 0 1rem;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    top: 0;
  `}
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 3rem;
  margin: 1rem 0;
  && a {
    color: ${(props) => props.theme.secondaryColor};
  }

  ${media.forSmallOnly`
    padding: 0.3rem 0;
  `};
`

const NavOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const RoutesContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  ${media.forSmallOnly`
    position: absolute;
    height: calc(100vh - ${({ theme }) => theme.mobileHeaderHeight});
    top: ${({ theme }) => theme.mobileHeaderHeight};
    left: 0;
    background-color: ${({ theme }) => theme.black};
    width: 100%;
    display: ${({ open }) => (open ? 'flex' : 'none')};
    flex-direction: column;
    padding: 1rem;
    z-index: 20;
    margin: 0;
    div {
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: none;
      padding: 1rem 0;
    }
  `}
`

const NavLinkContainer = styled.div`
  padding-left: 2rem;
  white-space: nowrap;

  a,
  button {
    font-weight: 600;
    color: ${(props) => props.theme.primaryColor} !important;
    text-decoration: none;
    position: relative;
    cursor: pointer;

    ${media.forSmallOnly`
    color: ${(props) => props.theme.defaultFontColor} !important;
    margin-bottom: 0.25rem;
    padding: .75rem;
    margin: 0;
    font-size: ${(props) => props.theme.defaultFontSize};
  `}
  }
`

const Logo = styled.h2`
  color: ${(props) => props.theme.defaultFontColor};
  font-size: 2.5rem;
  line-height: 2rem;
  font-weight: 800;
  letter-spacing: -0.025em;
  line-height: 1.2;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  &:hover {
    text-decoration: underline;
  }

  & > span {
    color: ${(props) => props.theme.primaryColor};
  }
`

const NavAuthLinks = observer(() => {
  const { userStore } = useStores()
  const history = useHistory()

  const handleLogOut = () => {
    userStore.logout()
    history.push('/login')
  }

  return userStore.token ? (
    <>
      {userStore.groups.has('buyers') && (
        <NavLinkContainer>
          <Link to="/buyer">Buyer</Link>
        </NavLinkContainer>
      )}
      {userStore.groups.has('pullers') && (
        <NavLinkContainer>
          <Link to="/puller">Puller</Link>
        </NavLinkContainer>
      )}
      <NavLinkContainer>
        <button onClick={handleLogOut}>Log Out</button>
      </NavLinkContainer>
    </>
  ) : (
    <>
      <NavLinkContainer>
        <Link to="/login">Log In</Link>
      </NavLinkContainer>
    </>
  )
})

export const NavComponent = ({ routes = ROUTES, navOptionsStyle = {} }) => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false)

  return (
    <NavContainer>
      <LogoContainer>
        <Link to="/login">
          <Logo>
            rennet
            <span>.</span>
            io
          </Logo>
        </Link>
      </LogoContainer>
      <NavOptionsContainer style={navOptionsStyle}>
        <Hamburger
          open={hamburgerOpen}
          onClick={() => setHamburgerOpen(!hamburgerOpen)}
        />
        <RoutesContainer open={hamburgerOpen}>
          {routes.map(({ route, text }) => {
            return (
              <NavLinkContainer key={route}>
                <Link to={route}>{text}</Link>
              </NavLinkContainer>
            )
          })}
          <NavAuthLinks />
        </RoutesContainer>
      </NavOptionsContainer>
    </NavContainer>
  )
}

export const NavBar = (props) => <NavComponent {...props} />
