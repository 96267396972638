import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../contexts'
import { BuyerSection } from '../components'
import { media } from '../styles'
import styled from 'styled-components'
import _ from 'lodash'

const MenuDivider = styled.hr`
  border: none;
  border-top: ${(props) => props.theme.border};
  width: 100%;
  size: 1px;
`

const MenuItem = styled.li`
  list-style: none;
  color: hsla(0, 0%, 100%, 0.65);
  font-size: 1rem;
  padding: 0.5rem 0;
  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }
`

const SideBarContainer = styled.div`
  display: none;
  padding: 0 1rem;

  ${media.forMediumUp`
    position: fixed;
    top:0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 10%;
    height: 100%;
  `}
`
const SideBarSubContainer = styled.div`
  margin-top: ${(props) => props.theme.desktopHeaderHeight};
  padding: 0.5rem 0;
  width: 100%;
`

const SideBarContent = observer(() => {
  const { pullerStore } = useStores()
  const sortedBuyers =
    pullerStore.buyers && _.sortBy(pullerStore.buyers, 'description')
  const sortBuckets = (bucketList) =>
    bucketList && _.sortBy(bucketList, ['state', 'created_time'])

  return (
    <>
      {sortedBuyers &&
        sortedBuyers.map((buyer) => (
          <div key={buyer.id}>
            <MenuItem>
              <Link to={`/#buyer-${buyer.id}`}>{buyer.name}</Link>
            </MenuItem>
            <MenuDivider />
            {buyer.buckets &&
              sortBuckets(buyer.buckets).map((bucket) => (
                <MenuItem key={bucket.id} sub>
                  <Link to={`/#bucket-${bucket.id}`}>{bucket.description}</Link>
                </MenuItem>
              ))}
          </div>
        ))}
    </>
  )
})

export const PullerTab = observer(() => {
  const { uiStore, userStore, pullerStore } = useStores()
  const history = useHistory()

  useEffect(() => {
    if (!userStore.groups.has('pullers')) {
      history.push('/login')
    }

    uiStore.setPageRole('puller')
    pullerStore.fetchData()
    pullerStore.connectSocket()
  }, [])

  return (
    <>
      <SideBarContainer>
        <SideBarSubContainer>
          <SideBarContent />
        </SideBarSubContainer>
      </SideBarContainer>
      <div>
        {pullerStore.buyers &&
          pullerStore.buyers.map((b) => (
            <div id={`buyer-${b.id}`} key={b.id}>
              <BuyerSection data={b} />
            </div>
          ))}
      </div>
    </>
  )
})
