import { HTTP_API_URL } from '../config'

export class AuthAPI {
  static login = async (data) => {
    let formData = new FormData()
    Object.keys(data).forEach((k) => {
      formData.append(k, data[k])
    })

    const config = {
      method: 'POST',
      body: formData,
    }

    return await fetch(`${HTTP_API_URL}/auth`, config)
  }

  static signUp = async (data) => {
    const config = {
      method: 'POST',
      body: JSON.stringify(data),
    }

    return await fetch(`${HTTP_API_URL}/users`, config)
  }

  static fetchSelf = async (token) => {
    const config = {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
      },
    }

    return await fetch(`${HTTP_API_URL}/self`, config)
  }
}
