import { css } from 'styled-components'

export const sizes = {
  forSmallOnly: {
    max: true,
    width: 680,
  },
  forSmallMediumOnly: {
    max: true,
    width: 991,
  },
  forMediumUp: {
    max: false,
    width: 681,
  },
  forLargeUp: {
    max: false,
    width: 992,
  },
  forXLargeUp: {
    max: false,
    width: 1280,
  },
  forXXLargeUp: {
    max: false,
    width: 1500,
  },
}
// .container {
//   width: 100%;
// }

// @media (min-width: 640px) {
//   .container {
//       max-width: 640px;
//   }
// }

// @media (min-width: 768px) {
//   .container {
//       max-width: 768px;
//   }
// }

// @media (min-width: 1024px) {
//   .container {
//       max-width: 1024px;
//   }
// }

// @media (min-width: 1280px) {
//   .container {
//       max-width: 1280px;
//   }
// }

// @media (min-width: 1536px) {
//   .container {
//       max-width: 1536px;
//   }
// }
// Iterate through the sizes and create a media template
export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => {
    if (sizes[label].max) {
      return css`
        @media (max-width: ${sizes[label].width / 16}em) {
          ${css(...args)};
        }
      `
    }
    return css`
      @media (min-width: ${sizes[label].width / 16}em) {
        ${css(...args)};
      }
    `
  }

  return acc
}, {})
