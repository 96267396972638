import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useStores } from '../contexts'
import { message } from 'antd'
import { Layout, BuyerEventsTab, BuyerSideNav } from '../components'
import { observer } from 'mobx-react'
import styled from 'styled-components'
import { Tabs } from 'antd'

const { TabPane } = Tabs

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

const Title = styled.h2`
  font-size: 1.3rem;
  font-weight: 500;
`

export const BuyerEvents = observer(() => {
  const { userStore, buyerStore } = useStores()
  const history = useHistory()

  useEffect(() => {
    if (userStore.groups.size == 0) {
      return
    }

    if (!userStore.groups.has('buyers') && userStore.groups.has('pullers')) {
      history.push('/puller/events')
      message.info(
        'Redirected to the puller page since you do not have buyer access',
      )
    } else if (!userStore.groups.has('buyers')) {
      history.push('/login')
    }

    if (buyerStore.buckets.length === 0) {
      buyerStore.fetchData(userStore.token)
    }
  })

  useEffect(() => {
    if (buyerStore.socket) {
      return
    }
    buyerStore.connectSocket()
  }, [buyerStore.socket])

  return userStore.isLoading ? (
    <Layout>loading</Layout>
  ) : (
    <Layout sidebar={<BuyerSideNav />}>
      <TitleContainer>
        <Title>Events</Title>
      </TitleContainer>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Active" key="1">
          <BuyerEventsTab isActive={1} />
        </TabPane>
        <TabPane tab="Archived" key="2">
          <BuyerEventsTab />
        </TabPane>
      </Tabs>
    </Layout>
  )
})
