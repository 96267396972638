import { HTTP_API_URL } from '../config'

export class ScreenshotAPI {
  static generateScreenshotUrls = async (token, data) => {
    const config = {
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    return await fetch(`${HTTP_API_URL}/screenshots`, config)
  }
}
