import React from 'react'
import { Layout } from '../components'

export function About() {
  return (
    <>
      <Layout>
        <h1>About</h1>
      </Layout>
    </>
  )
}
