import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { CloseOutlined, CheckOutlined, UndoOutlined } from '@ant-design/icons'
import { useStores } from '../contexts'

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
`

const StyledButton = styled(Button)`
  width: 100%;
  height: 100%;
  /* margin-left: ${(props) => (props.islast ? '10px' : 0)}; */
  margin-bottom: ${(props) => (props.islast ? 0 : '10px')};
`

export const BuyerActionCell = ({ data }) => {
  const { buyerStore } = useStores()
  return data.approval_state != 'Pending' ? (
    <ActionContainer>
      <StyledButton
        onClick={() => buyerStore.resetCart(data.id)}
        type="dashed"
        icon={<UndoOutlined />}
      >
        Reset Cart
      </StyledButton>
    </ActionContainer>
  ) : (
    <ActionContainer>
      <StyledButton
        onClick={() => buyerStore.declineCart(data.id)}
        type="danger"
        icon={<CloseOutlined />}
      >
        Decline
      </StyledButton>
      <StyledButton
        onClick={() => buyerStore.approveCart(data.id)}
        type="primary"
        icon={<CheckOutlined />}
        islast={1}
      >
        Approve
      </StyledButton>
    </ActionContainer>
  )
}
