import React from 'react'
import styled from 'styled-components'

const Line = styled.hr`
  border-color: rgba(51, 51, 51, 1);
  margin-top: 7rem /* 112px */;
  margin-bottom: 6rem /* 96px */;
`

export function SectionSeparator() {
  return <Line />
}
