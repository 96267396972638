import { makeAutoObservable } from 'mobx'
import { LS_AUTH_KEY } from '../config'
import { AuthAPI } from '../api'

export class UserStore {
  isLoading = false
  token = ''
  errors = {}
  user = {}
  groups = new Set()

  constructor() {
    makeAutoObservable(this)

    if (typeof window !== 'undefined') {
      const lsToken = window.localStorage.getItem(LS_AUTH_KEY)
      this.updateToken(lsToken || '')
    }

    if (Object.keys(this.user).length === 0) {
      this.fetchSelf()
    }
  }

  setIsLoading(bool) {
    this.isLoading = bool
  }

  setErrors(errors) {
    this.errors = errors
  }

  updateError(k, v) {
    this.errors[k] = v
  }

  setUser(user) {
    this.user = user
  }

  async login(data = {}) {
    this.setErrors({})
    let res

    try {
      res = await AuthAPI.login(data)
    } catch (error) {
      this.setErrors({ network: error })
      return
    }

    if (res.status === 401) {
      window.localStorage.removeItem(LS_AUTH_KEY)
      this.updateToken('')
    }

    if (!res.ok) {
      const errData = await res.json()
      const err = errData && Object.values(errData)[0]
      this.setErrors({ login: err })
      return
    }

    const resData = await res.json()
    const { token, user } = resData
    this.setUser(user)
    this.updateGroups(user.groups)
    this.updateToken(token)
    window.localStorage.setItem(LS_AUTH_KEY, token)
    return 'SUCCESS'
  }

  logout() {
    this.updateToken('')
    window.localStorage.removeItem(LS_AUTH_KEY)
    this.setUser({})
  }

  async fetchSelf() {
    this.setIsLoading(true)
    this.setErrors({})
    let res
    try {
      res = await AuthAPI.fetchSelf(this.token)
    } catch (error) {
      this.setErrors({ network: error })
      this.setIsLoading(false)
      return
    }

    if (res.status === 401) {
      window.localStorage.removeItem(LS_AUTH_KEY)
      this.updateToken('')
    }

    if (res.ok) {
      let user = await res.json()
      this.setUser(user)
      this.updateGroups(user.groups)
    }
    this.setIsLoading(false)
  }

  updateToken = (token) => {
    this.token = token
  }

  updateGroups = (groups) => {
    groups.forEach((groupname) => {
      this.groups.add(groupname)
    })
  }
}
