import { enableStaticRendering } from 'mobx-react'
import { toJS, configure } from 'mobx'
import React, { createContext, useContext } from 'react'
import { BuyerAPI, PullerAPI } from '../api'
import { UiStore, UserStore, BuyerStore, PullerStore } from '../stores'
import { isLocal } from '../config'

if (isLocal) {
  configure({
    enforceActions: 'observed',
    computedRequiresReaction: true,
    reactionRequiresObservable: true,
    warn: false,
  })
}
enableStaticRendering(typeof window === 'undefined')

let store
export const StoreContext = createContext()

export function useStores() {
  const context = useContext(StoreContext)
  if (context === undefined) {
    throw new Error('useStore must be used within StoreProvider')
  }

  return context
}

export function StoresProvider({ children }) {
  const store = initializeStore()

  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

function initializeStore() {
  let _store = store
  if (!_store) {
    let uiStore = new UiStore()
    let userStore = new UserStore()
    let buyerStore = new BuyerStore(BuyerAPI, userStore)
    let pullerStore = new PullerStore(PullerAPI, userStore)

    _store = {
      uiStore: uiStore,
      userStore: userStore,
      buyerStore: buyerStore,
      pullerStore: pullerStore,
    }
  }
  // Create the store once in the client
  if (!store) {
    store = _store
    // store.authStore.refreshAuth()
    window.stores = toJS(_store)
  }

  return _store
}
