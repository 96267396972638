import React from 'react'
import { Layout } from '../components'

export const NotFound = () => {
  return (
    <Layout>
      <h1>404 Not Found</h1>
    </Layout>
  )
}
