import React, { useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { useStores } from '../contexts'
import { Popover, Badge, Button } from 'antd'
import { observer } from 'mobx-react'
import { NotificationOutlined } from '@ant-design/icons'

const StyledNotificationIcon = styled(NotificationOutlined)`
  color: ${(props) => props.theme.primaryColor};
`

const StyledMessage = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  & > p {
    margin: 0;
    margin-right: 10px;
  }
`

export const NotificationBadgeButton = observer(({ isBuyer, bucketId }) => {
  const [popoverVisible, setPopoverVisible] = useState(false)
  const { buyerStore, pullerStore } = useStores()
  const dataStore = isBuyer ? buyerStore : pullerStore
  let notificationObj = _.get(dataStore.bucketNotifications, bucketId)

  const handleOnVisibleChange = (isVisible) => {
    setPopoverVisible(isVisible)
    dataStore.setBucketNotificationCount(bucketId, 0)
  }

  const notificationsList = () => {
    let messages = _.get(notificationObj, 'messages', [])
    let components = []
    _.forEachRight(messages, (m, i) => {
      components.push(
        <StyledMessage key={`${m.cartId}${i}`}>
          <p key={i}>{m.text}</p>
          <Button
            onClick={() => dataStore.setSelectedCart(m.cartId)}
            size="small"
          >
            Highlight It
          </Button>
        </StyledMessage>,
      )
    })

    return <div>{components.map((c) => c)}</div>
  }

  return (
    <Popover
      content={notificationsList()}
      title="Notifications"
      trigger="click"
      visible={popoverVisible}
      onVisibleChange={handleOnVisibleChange}
    >
      <Badge count={_.get(notificationObj, 'unreadCount', 0)} size="small">
        <Button>
          <StyledNotificationIcon />
        </Button>
      </Badge>
    </Popover>
  )
})
