import { HTTP_API_URL } from '../config'

export class BuyerAPI {
  /* BUCKETS */
  static fetchBuckets = async (token) => {
    const config = {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
      },
    }

    return await fetch(`${HTTP_API_URL}/buying/buckets`, config)
  }

  static fetchBucketDetails = async (token, bucketID) => {
    const config = {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
      },
    }

    return await fetch(`${HTTP_API_URL}/buying/buckets/${bucketID}`, config)
  }

  static createBucket = async (token, data) => {
    const config = {
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    return await fetch(`${HTTP_API_URL}/buying/buckets`, config)
  }

  static updateBucket = async (token, bucketID, data) => {
    const config = {
      method: 'PATCH',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    return await fetch(`${HTTP_API_URL}/buying/buckets/${bucketID}`, config)
  }

  static deleteBucket = async (token, bucketID) => {
    const config = {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${token}`,
      },
    }

    return await fetch(`${HTTP_API_URL}/buying/buckets/${bucketID}`, config)
  }

  /* CARTS */
  static fetchCartDetails = async (token, cartID) => {
    const config = {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
      },
    }

    return await fetch(`${HTTP_API_URL}/buying/carts/${cartID}`, config)
  }

  static updateCart = async (token, cartID, data) => {
    const config = {
      method: 'PATCH',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    return await fetch(`${HTTP_API_URL}/buying/carts/${cartID}`, config)
  }

  static generateScreenshotUrls = async (token, data) => {
    const config = {
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    return await fetch(`${HTTP_API_URL}/screenshots`, config)
  }
}
