export const theme = {
  primaryColor: '#1890ff',
  primaryColorHover: '#54adff',
  primaryColorFocus: '#217dd1',
  // darkPrimaryColor: 'rgba(129, 140, 248, 1)',
  // primaryColor: 'rgba(67, 56, 202, 1)',
  secondaryColor: '#FCA311',
  gray: '#E5E5E5',
  white: '#ffffff',
  black: '#000000',
  defaultHeaderColor: '#333333',
  disabledColor: '#888888',
  grayText: '#959595',
  desktopHeaderHeight: '80px',
  focusColor: '#4c9aff',
  linkColor: 'inherit',
  errorColor: '#e44100',
  negativeColor: '#D62828',
  positiveColor: '#2EC4B6',
  defaultInputBackgroundColor: '#f5f5f5',
  mobileHeaderHeight: '60px',
  borderColor: '#303030',
  border: '1px solid #303030',
  sidebarWidth: 200,
  backgroundColor: '#141414',
  defaultFontColor: 'white',
  defaultFont:
    'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
  defaultHeaderFontColor: '#10255D',
  defaultTransition: 'all 0.2s linear',
  defaultFontSize: '16px',
  defaultMobileFontSize: '14px',
}
