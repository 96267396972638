import React from 'react'
import styled from 'styled-components'
import { useState } from 'react'
import { CartCard, UploadCartCard } from '.'
import { media } from '../styles'
import { observer } from 'mobx-react'
import _ from 'lodash'
import { Select } from 'antd'
const { Option } = Select

const Container = styled.div`
  padding-bottom: 3rem;
`

const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  ${media.forMediumUp`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  `}
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`

const Title = styled.h4`
  font-size: 1.3rem;
  font-weight: 500;
  margin-left: 1rem;
`

const Detail = styled.p`
  margin-left: 1rem;
  font-size: 0.8rem;
  font-weight: 300;
`

const SelectContainer = styled.span`
  display: flex;
  align-items: baseline;
  & > p {
    margin-right: 0.5rem;
  }
  & > .ant-select {
    min-width: 200px;
  }
`

const Grid = styled.div`
  display: grid;
  gap: 1rem;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
  ${media.forMediumUp`
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  `}
`

const CardContainer = styled.div`
  transition: all 0.1s ease;
`

export const BucketCard = observer(({ data }) => {
  const [checkoutStateFilter, setCheckoutStateFilter] = useState('Incomplete')
  let processedCarts =
    data.carts &&
    _.sortBy(data.carts, ['checkout_state', 'approval_state', 'created_time'])
  if (checkoutStateFilter) {
    processedCarts = _.filter(processedCarts, {
      checkout_state: checkoutStateFilter,
    })
  }

  return (
    <Container>
      <HeadContainer>
        <TitleContainer>
          <UploadCartCard bucketID={data.id} small />
          <span>
            <Title>{data.description}</Title>
            <Detail>Type: {data.type}</Detail>
          </span>
        </TitleContainer>
        <SelectContainer>
          <p>Checkout State:</p>
          <Select
            value={checkoutStateFilter}
            onChange={(v) => setCheckoutStateFilter(v)}
            placeholder="Filter"
          >
            <Option value="Incomplete">Incomplete</Option>
            <Option value="Completed">Completed</Option>
            <Option value="Expired">Expired</Option>
            <Option value="">All</Option>
          </Select>
        </SelectContainer>
      </HeadContainer>
      <Grid>
        {processedCarts &&
          processedCarts.map((c) => (
            <CardContainer key={c.id}>
              <CartCard data={c} />
            </CardContainer>
          ))}
        <CardContainer>
          <UploadCartCard bucketID={data.id} />
        </CardContainer>
      </Grid>
    </Container>
  )
})
