export const isLocal = true

export const HTTP_API_URL = process.env.REACT_APP_API_HOST_HTTP
export const WS_API_URL = process.env.REACT_APP_API_HOST_WS

export const GTM_ID = process.env.REACT_APP_GTM_ID

export const LS_AUTH_KEY = '__decart_token__'

export const PATH_PREFIX = '/decart'
