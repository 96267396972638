import { observable, action, makeObservable } from 'mobx'

export class UiStore {
  isModalDisplayed = false
  modalImgSrc = ''
  screenWidth = typeof window !== 'undefined' && window.screen.width
  pageRole = ''

  constructor() {
    makeObservable(this, {
      isModalDisplayed: observable,
      modalImgSrc: observable,
      screenWidth: observable,
      setIsModalDisplayed: action,
      setModalImgSrc: action,
      openModal: action,
      closeModal: action,
    })
  }

  setIsModalDisplayed = (value) => {
    this.isModalDisplayed = value
  }

  setModalImgSrc = (url) => {
    this.modalImgSrc = url
  }

  setPageRole = (role) => {
    this.pageRole = role
  }

  openModal = (fullImgUrl) => {
    this.modalImgSrc = fullImgUrl
    this.isModalDisplayed = true
  }

  closeModal = () => {
    this.modalImgSrc = ''
    this.isModalDisplayed = false
  }
}
