import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { useStores } from '../contexts'
import { CreateBucketModal, Badge } from '../components'
import styled from 'styled-components'
import _ from 'lodash'
import { Menu } from 'antd'
const MenuItem = Menu.Item

const MenuTitle = styled.p`
  padding-left: 24px;
  margin-top: 1.2rem;
  color: hsla(0, 0%, 100%, 0.65);
  font-size: 1.2rem;
`

const StyledMenuItem = styled(MenuItem)`
  white-space: normal;
  line-height: normal !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const BuyerSideNav = observer(() => {
  const { buyerStore } = useStores()

  const renderBadge = (bucketId) => {
    let unreadCount = _.get(
      buyerStore.bucketNotifications,
      `${bucketId}.unreadCount`,
      0,
    )
    if (unreadCount > 0) {
      return <Badge count={unreadCount} />
    }
  }

  return (
    <>
      <MenuTitle>
        <Link to="/buyer/events">My Events</Link>
      </MenuTitle>
      <Menu mode="inline">
        <Menu.Item>
          <CreateBucketModal />
        </Menu.Item>
        {buyerStore.activeBuckets &&
          _.sortBy(buyerStore.activeBuckets, 'description').map((bucket) => (
            <StyledMenuItem key={bucket.id}>
              <Link to={`/buyer/events/${bucket.id}`}>
                {bucket.description}
              </Link>
              {renderBadge(bucket.id)}
            </StyledMenuItem>
          ))}
      </Menu>
    </>
  )
})
