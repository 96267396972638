import { HTTP_API_URL } from '../config'

export class PullerAPI {
  /* BUYERS */
  static fetchBuyers = async (token) => {
    const config = {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
      },
    }

    return await fetch(`${HTTP_API_URL}/pulling/buyers`, config)
  }

  /* SCREENSHOTS */
  static generateUploadScreenshotUrl = async (token, bucketID, data) => {
    const config = {
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    return await fetch(
      `${HTTP_API_URL}/pulling/buckets/${bucketID}/screenshots`,
      config,
    )
  }

  /* BUCKETS */
  static fetchBuckets = async (token) => {
    const config = {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
      },
    }

    return await fetch(`${HTTP_API_URL}/pulling/buckets`, config)
  }

  static fetchBucketDetails = async (token, bucketID) => {
    const config = {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
      },
    }

    return await fetch(`${HTTP_API_URL}/pulling/buckets/${bucketID}`, config)
  }

  /* CARTS */
  static fetchBucketCarts = async (token, bucketID) => {
    const config = {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
      },
    }

    return await fetch(
      `${HTTP_API_URL}/pulling/buckets/${bucketID}/carts`,
      config,
    )
  }

  static fetchCartDetails = async (token, cartID) => {
    const config = {
      method: 'GET',
      headers: {
        Authorization: `Token ${token}`,
      },
    }

    return await fetch(`${HTTP_API_URL}/pulling/carts/${cartID}`, config)
  }

  static createCart = async (token, bucketID, data) => {
    const config = {
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    return await fetch(
      `${HTTP_API_URL}/pulling/buckets/${bucketID}/carts`,
      config,
    )
  }

  static updateCart = async (token, cartID, data) => {
    const config = {
      method: 'PATCH',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    return await fetch(`${HTTP_API_URL}/pulling/carts/${cartID}`, config)
  }

  static generateScreenshotUrls = async (token, data) => {
    const config = {
      method: 'POST',
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }

    return await fetch(`${HTTP_API_URL}/screenshots`, config)
  }
}
