import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '../contexts'
import { BucketCard } from '../components'
import { media } from '../styles'
import { Input, Button } from 'antd'
import styled from 'styled-components'
import _ from 'lodash'

const MenuTitle = styled.p`
  margin-top: 1.2rem;
  color: hsla(0, 0%, 100%, 0.65);
  font-size: 1.2rem;
`

const MenuItem = styled.li`
  list-style: none;
  color: hsla(0, 0%, 100%, 0.65);
  font-size: 1rem;
  padding: 0.5rem 0;
  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }
`

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  width: 100%;
  height: max-content;
  white-space: normal;
  margin-bottom: 24px;
`

const SideBarContainer = styled.div`
  display: none;
  padding: 0 1rem;

  ${media.forMediumUp`
    position: fixed;
    top:0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 10%;
    height: 100%;
  `}
`
const SideBarSubContainer = styled.div`
  margin-top: ${(props) => props.theme.desktopHeaderHeight};
  padding: 0.5rem 0;
  width: 100%;
`

const SideBarContent = observer(() => {
  const { buyerStore } = useStores()
  const [bucketDescription, setBucketDescription] = useState('')
  const sortedBuckets =
    buyerStore.buckets && _.sortBy(buyerStore.buckets, 'description')

  const handleCreateBucket = () => {
    buyerStore.createBucket(bucketDescription)
    setBucketDescription('')
  }

  return (
    <>
      <MenuTitle>Buckets</MenuTitle>
      <span>
        <Input
          value={bucketDescription}
          onChange={(e) => setBucketDescription(e.target.value)}
          onPressEnter={() => handleCreateBucket()}
        />
        <StyledButton type="primary" ghost onClick={() => handleCreateBucket()}>
          Create Bucket
        </StyledButton>
      </span>
      {sortedBuckets &&
        sortedBuckets.map((bucket) => (
          <div key={bucket.id}>
            <MenuItem>
              <Link to={`/#bucket-${bucket.id}`}>{bucket.description}</Link>
            </MenuItem>
          </div>
        ))}
    </>
  )
})

export const BuyerTab = observer(() => {
  const { uiStore, userStore, buyerStore } = useStores()
  const history = useHistory()
  const sortedBuckets =
    buyerStore.buckets && _.sortBy(buyerStore.buckets, 'description')

  useEffect(() => {
    if (!userStore.groups.has('buyers')) {
      history.push('/login')
    }

    uiStore.setPageRole('buyer')
    buyerStore.fetchData()
    buyerStore.connectSocket()
  }, [])

  return (
    <>
      <SideBarContainer>
        <SideBarSubContainer>
          <SideBarContent />
        </SideBarSubContainer>
      </SideBarContainer>
      <div>
        {sortedBuckets &&
          sortedBuckets.map((b) => (
            <div id={`bucket-${b.id}`} key={b.id}>
              <BucketCard data={b} />
            </div>
          ))}
      </div>
    </>
  )
})
