import React from 'react'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useStores } from '../contexts'
import { Layout } from '../components'
import { observer } from 'mobx-react'

export const Home = observer(() => {
  const { userStore } = useStores()
  const history = useHistory()
  useEffect(() => {
    if (userStore.groups.has('buyers')) {
      history.push('/buyer/events')
    } else if (userStore.groups.has('pullers')) {
      history.push('/puller/events')
    } else {
      history.push('/login')
    }
  })

  return <Layout showSideBar></Layout>
})
