import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { observer } from 'mobx-react'
import { BucketCard } from '../components'
import { Divider } from 'antd'

const Container = styled.div`
  padding: 2rem 0;
`

const Title = styled.h4`
  font-size: 1.5rem;
  font-weight: 700;
`

export const BuyerSection = observer(({ data }) => {
  const sortedBuckets = data.buckets && _.sortBy(data.buckets, 'description')

  return (
    <Container>
      <Title>{data.name}</Title>
      <Divider />
      {sortedBuckets &&
        sortedBuckets.map((b) => (
          <div id={`bucket-${b.id}`} key={b.id}>
            <BucketCard data={b} />
          </div>
        ))}
    </Container>
  )
})
