import React from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { useStores } from '../contexts'

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
`

const StyledButton = styled(Button)`
  width: 100%;
  height: 100%;
  /* margin-left: ${(props) => (props.islast ? '10px' : 0)}; */
  margin-bottom: ${(props) => (props.islast ? 0 : '10px')};
`

export const PullerActionCell = ({ data }) => {
  const { pullerStore } = useStores()
  const handleUpdateCartCheckoutState = (checkoutState) => {
    pullerStore.updateCartCheckoutState(data.id, checkoutState)
  }

  return (
    <ActionContainer>
      <p>Mark Checkout State As:</p>
      {data.checkout_state !== 'Incomplete' && (
        <StyledButton
          onClick={() => handleUpdateCartCheckoutState('incomplete')}
        >
          Incomplete
        </StyledButton>
      )}
      {data.checkout_state !== 'Completed' && (
        <StyledButton
          onClick={() => handleUpdateCartCheckoutState('completed')}
        >
          Completed
        </StyledButton>
      )}
      {data.checkout_state !== 'Expired' && (
        <StyledButton onClick={() => handleUpdateCartCheckoutState('expired')}>
          Expired
        </StyledButton>
      )}
    </ActionContainer>
  )
}
