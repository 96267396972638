import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { observer } from 'mobx-react'
import { useStores } from '../contexts'
import { Layout } from '../components'

const Container = styled.div`
  max-width: 28rem;
  margin-top: 2.5rem /* 40px */;
  margin-bottom: 2.5rem /* 40px */;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.25rem;
`

const TitleContainer = styled.div`
  text-align: center;

  & > h1 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 1.875rem;
    line-height: 2.25rem;
    font-weight: 600;
    color: rgba(229, 231, 235, 1);
  }

  & > p {
    color: rgba(​128, ​128, ​128, 1);
  }
`

const StyledForm = styled.form`
  margin-bottom: 1.75rem;
`

const InputContainer = styled.div`
  margin-bottom: 1.5rem /* 24px */;
  & > button {
    width: 100%;
    padding: 1rem 0.75rem;
    border-radius: 0.375rem /* 6px */;
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.primaryColor};
    margin-bottom: 1.5rem /* 24px */;

    &:focus {
      background-color: ${(props) => props.theme.primaryColor};
    }
  }

  & > label {
    display: block;
    margin-bottom: 0.5rem;
    margin-bottom: 1.5rem /* 24px */;
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    color: rgba(156, 163, 175, 1);
  }

  & > input {
    width: 100%;
    color: ${(props) => props.theme.white};
    padding-left: 0.75rem /* 12px */;
    padding-right: 0.75rem /* 12px */;
    padding-top: 0.5rem /* 8px */;
    padding-bottom: 0.5rem /* 8px */;
    background-color: rgba(55, 65, 81, 1);
    border-color: rgba(75, 85, 99, 1);
    border-radius: 0.375rem /* 6px */;

    &::placeholder {
      color: rgba(107, 114, 128, 1);
    }

    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
      border-color: rgba(17, 24, 39, 1);
    }
  }
`

const PassLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  & > label {
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    color: rgba(156, 163, 175, 1);
  }

  && > a {
    color: ${(props) => props.theme.primaryColor};
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;

    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
      color: rgba(99, 102, 241, 1);
    }

    &:hover {
      color: ${(props) => props.theme.primaryColorHover};
    }
  }
`

const StyledText = styled.p`
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  text-align: center;
  color: rgba(128, 128, 128, 1);

  && > a {
    color: ${(props) => props.theme.primaryColor};

    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
      text-decoration: underline;
      color: ${(props) => props.theme.primaryColorFocus};
      border-color: ${(props) => props.theme.primaryColorFocus};
    }

    &:hover {
      color: ${(props) => props.theme.primaryColorHover};
    }
  }
`

export const Login = observer(() => {
  const { userStore } = useStores()
  const history = useHistory()

  useEffect(() => {
    if (userStore.groups.has('buyers')) {
      history.push('/buyer')
    } else if (userStore.groups.has('pullers')) {
      history.push('/puller')
    }
  })

  const handleLogin = async (e) => {
    e.preventDefault()
    const form = e.target
    const formData = new window.FormData(form)
    const username = formData.get('username')
    const password = formData.get('password')

    await userStore.login({
      email_or_username: username,
      password: password,
    })

    history.push('/')
  }

  return userStore.isLoading ? (
    <Layout>loading</Layout>
  ) : (
    <Layout>
      <Container>
        <TitleContainer>
          <h1>Log In</h1>
          <p>Log into access your account</p>
        </TitleContainer>
        <StyledForm action="true" onSubmit={handleLogin}>
          <InputContainer>
            <label htmlFor="username">Username</label>
            <input
              type="username"
              name="username"
              id="username"
              placeholder="Enter Username"
            />
          </InputContainer>
          <InputContainer>
            <PassLabelContainer>
              <label htmlFor="password">Password</label>
              <a href="#!">Forgot password?</a>
            </PassLabelContainer>
            <input
              type="password"
              name="password"
              id="password"
              placeholder="Your Password"
            />
          </InputContainer>
          <InputContainer>
            {/* {error && <p className="text-red-700">{`Error: ${error}`}</p>} */}
            <button type="submit">Log In</button>
            <StyledText>
              Don&apos;t have an account yet? <a href="#!">Sign up</a>.
            </StyledText>
          </InputContainer>
        </StyledForm>
      </Container>
    </Layout>
  )
})
