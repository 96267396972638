import { makeAutoObservable } from 'mobx'
import { WS_API_URL } from '../config'
import _ from 'lodash'
export class PullerStore {
  pullerApi
  userStore
  socket
  isLoading = false
  buyers = []
  buckets = []
  bucketNotifications = {}
  screenshots = {}
  screenshotIds = []
  selectedBucketId = -1
  selectedCartId = -1

  constructor(pullerApi, userStore) {
    makeAutoObservable(this, {
      pullerApi: false,
      userStore: false,
    })

    this.pullerApi = pullerApi
    this.userStore = userStore
  }

  resetData() {
    this.buyers = []
    this.buckets = []
    this.screenshots = {}
    this.screenshotIds = []
  }

  get activeBuckets() {
    return this.buckets.filter((b) => b.state === 'Active')
  }

  get archivedBuckets() {
    return this.buckets.filter((b) => b.state === 'Archived')
  }

  getBucket(id) {
    return _.find(this.buckets, (b) => b.id === parseInt(id))
  }

  setSelectedBucket(id) {
    if (typeof id === 'string') {
      id = parseInt(id)
    }

    this.selectedBucketId = id
    let bucketI = _.findIndex(this.buckets, { id: id })
    if (bucketI === -1) return
    this.buckets[bucketI].noti = 0
  }

  setBuyers(buyers) {
    this.buyers = buyers
  }

  setBuckets(buckets) {
    this.buckets = buckets
    buckets.forEach((b) =>
      this.setBucketNotificationObjs(b.id, {
        unreadCount: 0,
        messages: [],
      }),
    )
  }

  setBucketNotificationObjs(bucketId, obj) {
    this.bucketNotifications[bucketId] = obj
  }

  setBucketNotificationMessages(bucketId, messages) {
    this.bucketNotifications[bucketId].messages = messages
  }

  setBucketNotificationCount(bucketId, count) {
    this.bucketNotifications[bucketId].unreadCount = count
  }

  setSelectedCart(id) {
    if (typeof id === 'string') {
      id = parseInt(id)
    }

    this.selectedCartId = id
  }

  setScreenshotIds(ids) {
    this.screenshotIds = ids
  }

  setIsLoading(bool) {
    this.isLoading = bool
  }

  async fetchData() {
    if (!this.userStore.groups.has('pullers') || !this.userStore.token) {
      return
    }

    try {
      this.setIsLoading(true)
      const res = await this.pullerApi.fetchBuckets(this.userStore.token)
      let data = await res.json()
      this.setBuckets(data)

      let carts = data.map((b) => b.carts).flat()
      this.setScreenshotIds(carts.map((c) => c.cart_screenshot))

      this.fetchScreenshotsFromIds(this.screenshotIds)
    } catch (error) {
      console.log(error)
    } finally {
      this.setIsLoading(false)
    }
  }

  resyncData() {
    this.resetData()
    this.fetchData()
  }

  async fetchScreenshotsFromIds(ids) {
    let res = await this.pullerApi.generateScreenshotUrls(
      this.userStore.token,
      {
        ids: ids,
      },
    )
    let screenshotsData = await res.json()
    screenshotsData.forEach((d) => {
      this.updateScreenshotData(d)
    })
  }

  async uploadScreenshot({ file }, bucketId) {
    let splitName = file.name.split('.')
    let extension = splitName[splitName.length - 1]

    let res = await this.pullerApi.generateUploadScreenshotUrl(
      this.userStore.token,
      bucketId,
      {
        extension: extension,
      },
    )
    let signedData = await res.json()

    let form = new FormData()
    Object.keys(signedData.post_fields).forEach((key) =>
      form.append(key, signedData.post_fields[key]),
    )
    form.append('file', file)

    let config = { method: 'POST', body: form }
    await fetch(signedData.post_url, config)

    return signedData.screenshot_id
  }

  async createCart(bucketId, screenshotId) {
    // create cart
    let createCartRes = await this.pullerApi.createCart(
      this.userStore.token,
      bucketId,
      {
        cart_screenshot: screenshotId,
      },
    )

    await createCartRes.json()
  }

  async updateCartCheckoutState(id, checkoutState) {
    let res = await this.pullerApi.updateCart(this.userStore.token, id, {
      checkout_state: checkoutState,
    })

    if (!res.ok) {
      let errData = await res.json()
      errData.timestamp = Date.now()
      console.log(errData)
      return
    }
  }

  updateScreenshotData(data) {
    this.screenshots[data.id] = data
  }

  wsUpdateBucket(bucketData) {
    let i = _.findIndex(this.buckets, { id: bucketData.id })
    if (i === -1) {
      this.buckets.push(bucketData)
    } else {
      this.buckets[i] = bucketData
    }

    if (!this.bucketNotifications[bucketData.id]) {
      this.setBucketNotificationObjs(bucketData.id, {
        unreadCount: 0,
        messages: [],
      })
    }
  }

  wsUpdateCart(cartData, adj) {
    if (
      !cartData.puller ||
      cartData.puller != _.get(this.userStore.user, 'id')
    ) {
      return
    }

    let bucketI = _.findIndex(this.buckets, { id: cartData.bucket })
    if (bucketI === -1) return
    // update bucket notifications
    let notificationObj = this.bucketNotifications[cartData.bucket]
    let messages = notificationObj.messages
    let message = {
      cartId: cartData.id,
      text: `Cart ${adj}`,
    }
    notificationObj.messages.push(message)
    this.setBucketNotificationMessages(cartData.bucket, messages)
    this.setBucketNotificationCount(
      cartData.bucket,
      notificationObj.unreadCount + 1,
    )

    let curCarts = this.buckets[bucketI].carts
    let cartI = _.findIndex(curCarts, { id: cartData.id })
    if (cartI !== -1) {
      // check version number to see if you still want to update.
      if (curCarts[cartI].version < cartData.version) {
        curCarts[cartI] = cartData
      }
      return
    }

    curCarts.push(cartData)
    // update screenshots for new cart
    this.screenshotIds.push(cartData.cart_screenshot)
    this.fetchScreenshotsFromIds([cartData.cart_screenshot])
  }

  connectSocket() {
    if (typeof window === 'undefined' || !this.userStore.token) return

    this.socket = new WebSocket(
      `${WS_API_URL}/ws?api_token=${this.userStore.token}`,
    )

    this.socket.onopen = () => {
      console.log('connected to websocket')
    }

    // add onmessage here
    this.socket.onmessage = (e) => {
      let m = JSON.parse(e.data)

      switch (m.type) {
        case 'buyer_added':
          this.resyncData()
          break
        case 'buyer_removed':
          this.resyncData()
          break
        case 'bucket_created':
          this.wsUpdateBucket(m.data)
          break
        case 'bucket_updated':
          this.wsUpdateBucket(m.data)
          break
        case 'bucket_deleted':
          this.wsDeleteBucket(m.data)
          break
        case 'cart_created':
          this.wsUpdateCart(m.data, 'created')
          break
        case 'cart_updated':
          this.wsUpdateCart(m.data, 'updated')
          break
      }
    }
  }
}
