import React, { useState } from 'react'
import { message, Modal, Button, Input, Select } from 'antd'
import styled from 'styled-components'
import { useStores } from '../contexts'
const { Option } = Select

const StyledButton = styled(Button)`
  &&&&.ant-btn {
    height: auto;
    white-space: normal;
    width: 100%;
  }
`

const InputContainer = styled.div`
  margin-bottom: 1rem;
`

const StyledSelect = styled(Select)`
  min-width: 200px;
`

export const CreateBucketModal = () => {
  const { buyerStore } = useStores()
  const [visible, setVisible] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [bucketDescription, setBucketDescription] = useState('')
  const [bucketLink, setBucketLink] = useState('')
  const [bucketType, setBucketType] = useState('')

  const showModal = () => {
    setVisible(true)
  }

  const handleOk = async () => {
    if (!bucketType || !bucketDescription) {
      message.error('Need to fill out both Bucket Description and Bucket Type')
      return
    }
    setConfirmLoading(true)
    await buyerStore.createBucket(bucketDescription, bucketType, bucketLink)
    setBucketDescription('')
    setBucketLink('')
    setVisible(false)
    setConfirmLoading(false)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <StyledButton type="primary" onClick={showModal}>
        Create Event
      </StyledButton>
      <Modal
        title="Create Event"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <InputContainer>
          <p>Description *</p>
          <Input
            value={bucketDescription}
            onChange={(e) => setBucketDescription(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <p>Type *</p>
          <StyledSelect value={bucketType} onChange={(v) => setBucketType(v)}>
            <Option value="ticketmaster.com">ticketmaster.com</Option>
            <Option value="axs.com">axs.com</Option>
            <Option value="none">Other</Option>
          </StyledSelect>
        </InputContainer>
        <InputContainer>
          <p>Link</p>
          <Input
            value={bucketLink}
            onChange={(e) => setBucketLink(e.target.value)}
          />
        </InputContainer>
      </Modal>
    </>
  )
}
